
import { defineComponent, PropType } from "vue";
import { ArrayView } from "./array-view";

interface PoolItem<T> {
    item: T;
    index: number;
}

export default defineComponent({
    name: "VirtualScroller",
    emits: ["update:scrollTop"],
    props: {
        items: {
            type: Array as PropType<unknown[]>,
            required: true,
        },
        itemSize: {
            type: Number,
            required: true,
        },
        keyProp: {
            type: String,
            default: () => "id",
        },
        bufferSize: {
            type: Number,
            default: () => 200,
        },
        active: Number,
    },
    data() {
        return {
            requested: false,
            scrolling: false,
            pool: new ArrayView<unknown>([], 0),
        };
    },
    mounted() {
        this.updatePool();
    },
    computed: {
        planeHeight(): number {
            return this.items.length * this.itemSize;
        },
        view(): HTMLDivElement {
            return this.$refs.view as HTMLDivElement;
        },
    },
    methods: {
        onScroll(): void {
            this.scrolling = true;
            const view = this.$refs.view as HTMLDivElement | undefined;
            const scrollTop = view?.scrollTop || 0;

            if (!this.requested) {
                requestAnimationFrame(() => {
                    this.$emit("update:scrollTop", scrollTop);
                    this.updatePool();
                    this.requested = false;
                    this.scrolling = false;
                });
                this.requested = true;
            }
        },
        getPoolSize(): number {
            const itemsCountInBuffer = Math.ceil(this.bufferSize / this.itemSize);
            const itemsCountInView = Math.ceil(this.view.clientHeight / this.itemSize);
            return itemsCountInBuffer * 2 + itemsCountInView;
        },
        getFirst(): number {
            const firstItem = Math.floor((this.view.scrollTop - this.bufferSize) / this.itemSize);
            return Math.max(0, firstItem);
        },
        getLast(): number {
            const lastItem =
                Math.ceil((this.view.scrollTop + this.view.clientHeight + this.bufferSize) / this.itemSize) + 1;
            return Math.min(this.items.length, lastItem);
        },
        updatePool() {
            const firstIndex = this.getFirst();
            const poolSize = this.getPoolSize();

            if (poolSize > this.pool.viewSize) {
                this.pool = new ArrayView<unknown>(this.items, poolSize, firstIndex);
            } else {
                this.pool.setStartIndex(firstIndex);
            }
        },
        transform(poolItem: PoolItem<unknown> | null): string {
            const index = poolItem?.index ?? 0;
            return `translateY(${index * this.itemSize}px)`;
        },
    },
    watch: {
        items: {
            handler() {
                const view = this.$refs.view as HTMLDivElement;
                if (view) view.scrollTop = 0;
                this.pool = new ArrayView(this.items, this.pool.viewSize);
            },
            deep: true,
        },
        itemSize() {
            this.updatePool();
        },
        active() {
            const view = this.$refs.view as HTMLDivElement | undefined;
            if (view && !this.scrolling && typeof this.active === "number") {
                if (this.active * this.itemSize < view.scrollTop) {
                    view.scrollTop = this.active * this.itemSize;
                    this.updatePool();
                } else if ((this.active + 1) * this.itemSize > view.scrollTop + view.clientHeight) {
                    view.scrollTop = (this.active + 1) * this.itemSize - view.clientHeight;
                    this.updatePool();
                }
            }
        },
    },
});
