import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, renderSlot as _renderSlot, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, mergeProps as _mergeProps } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _mergeProps({ ref: "view" }, _ctx.$attrs, {
    class: "virtual-scroller",
    onScrollPassive: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onScroll && _ctx.onScroll(...args)))
  }), [
    _createElementVNode("div", {
      class: "virtual-scroller-plane",
      style: _normalizeStyle({ height: _ctx.planeHeight + 'px' })
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.pool, (poolItem, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: index,
          style: _normalizeStyle({ transform: _ctx.transform(poolItem) }),
          class: "virtual-scroller-element"
        }, [
          _renderSlot(_ctx.$slots, "default", {
            item: poolItem.item,
            index: poolItem.index
          }, undefined, true)
        ], 4))
      }), 128))
    ], 4)
  ], 16))
}