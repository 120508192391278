
import { defineComponent, PropType } from "vue";
import { VirtualScroller } from "@kunsten/utils";

type MultiSelectOption<T> = {
    value: T;
    text: string;
    hitCount: number;
};

let uniqueId = 0;

export default defineComponent({
    name: "MultiSelect",
    emits: ["update:modelValue"],
    components: { VirtualScroller },
    props: {
        modelValue: {
            type: Array,
            default: () => [],
        },
        options: {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            type: Array as PropType<MultiSelectOption<any>[]>,
            default: () => [],
        },
        search: {
            type: Boolean,
            default: false,
        },
        single: {
            type: Boolean,
            default: false,
        },
        placeholder: {
            type: String,
        },
        label: {
            type: String,
        },
        sort: {
            type: String,
            default: "asc",
        },
        clearBtn: {
            type: Boolean,
            default: true,
        },
        hidden: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            open: false,
            value: this.modelValue,
            searchValue: "",
            activeIndex: 0,
            currentId: uniqueId++,
        };
    },
    mounted() {
        document.addEventListener("click", this.onOutsideClick);
        document.addEventListener("keydown", this.onKeyboardDown);
        window.addEventListener("keydown", this.onWindowKeyboardDown);
    },
    unmounted() {
        document.removeEventListener("click", this.onOutsideClick);
        document.removeEventListener("keydown", this.onKeyboardDown);
        window.removeEventListener("keydown", this.onWindowKeyboardDown);
    },
    computed: {
        buttonText() {
            if (!this.value?.length) return this.placeholder;
            const texts = this.value.map((v) => this.options.find((o) => o.value === v)?.text);
            return texts.join(", ");
        },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        getOptions(): MultiSelectOption<any>[] {
            if (!this.search || !this.searchValue) return this.options;
            return this.options.filter((o) => o.text.toLowerCase().includes(this.searchValue.toLowerCase()));
        },
    },
    methods: {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        onSelect(value: any) {
            if (this.single) {
                this.value = [value];
                this.open = false;
            } else {
                if (this.isSelected(value)) {
                    this.value = this.value.filter((v) => v !== value);
                } else {
                    this.value = [...this.value, value];
                }
            }
            this.$emit("update:modelValue", this.value);
        },
        clear() {
            this.value = [];
            this.$emit("update:modelValue", this.value);
        },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        isSelected(value: any) {
            return this.value.includes(value);
        },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        getTextByValue(value: any) {
            return this.options.find((option) => option.value === value)?.text;
        },
        onOutsideClick(ev: MouseEvent | FocusEvent) {
            if (!this.open) return;
            const container = this.$refs.container as HTMLDivElement;
            if (container && !container.contains(ev.target as Element)) this.open = false;
        },
        onKeyboardDown(e: KeyboardEvent) {
            if (!this.open) return;
            if (e.key === "Escape") this.open = false;
            if (e.key === "ArrowUp") {
                if (this.activeIndex > 0) this.activeIndex -= 1;
            }
            if (e.key === "ArrowDown") {
                if (this.activeIndex < this.getOptions.length - 1) this.activeIndex += 1;
            }
            if (e.key === "Enter") {
                // when select is open we want control options via enter instead of
                // firing events on focused button and doing bad things
                if (this.open === true) e.preventDefault();
                const value = this.getOptions[this.activeIndex]?.value;
                if (value) this.onSelect(value);
            }
        },
        // prevent page scrolling while navigating through multi select
        onWindowKeyboardDown(e: KeyboardEvent) {
            if (this.open && (e.key === "ArrowUp" || e.key === "ArrowDown")) {
                e.preventDefault();
            }
        },
        activeOptionId(index: number) {
            return `${this.currentId}-active-option-${index}`;
        },
        getSortedOptions() {
            if (this.sort === "desc") return this.getOptions.sort((a, b) => b.text.localeCompare(a.text));
            return this.getOptions.sort((a, b) => a.text.localeCompare(b.text));
        },
    },
    watch: {
        open(currOpen, prevOpen) {
            if (currOpen && !prevOpen) {
                this.searchValue = "";
            }
        },
        modelValue(newValue) {
            this.value = newValue;
        },
    },
});
