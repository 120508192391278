export class ArrayView<T> {
    private start = 0;

    constructor(private array: T[], private _viewSize: number, private startIndex: number = 0) {}

    get viewSize(): number {
        return this._viewSize;
    }

    setArray(array: T[]) {
        this.array = array;
    }

    setStartIndex(newStartIndex: number) {
        const indexDiff = (newStartIndex % this.viewSize) - (this.startIndex % this.viewSize);
        this.start = (indexDiff + this.start + this.viewSize) % this.viewSize;
        this.startIndex = newStartIndex;
    }

    *[Symbol.iterator]() {
        for (let i = 0; i < this.viewSize; i++) {
            const arrayIndex = ((this.viewSize + i - this.start) % this.viewSize) + this.startIndex;
            if (arrayIndex >= this.array.length) continue;
            yield { item: this.array[arrayIndex], index: arrayIndex, viewIndex: i };
        }
    }
}
